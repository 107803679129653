<template>
    <div>
        <slot name="trigger" :show="show">
        </slot>
        <transition name="modal" v-cloak>
            <div v-if="showModal" class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container" :class="[{'slide-left': slidingLeft, 'slide-right': slidingRight, 'slide-in': slideInOnMount, 'rounded-2xl': rounded}, modalClass]" v-click-outside="onOutsideClicked">
                        <div class="modal-header" :class="{'rounded-2xl': rounded}" :style="headerStyle">
                            <div class="modal-back-btn-wrapper">
                                <transition name="fade-fast">
                                    <span v-if="showBackBtn" @click="$emit('back')"><i class="fas fa-caret-left modal-header-btn"></i></span>
                                </transition>
                            </div>
                            <slot name="header">
                                <h3>{{ title }}</h3>
                            </slot>
                            <div class="modal-close-btn-wrapper">
                                <transition name="fade-fast">
                                    <span v-if="showCloseBtn" @click="close()" class="modal-header-btn">&times;</span>
                                </transition>
                            </div>
                        </div>
                        <div class="modal-body" :class="{'rounded-2xl': rounded}">
                            <slot :close="close"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    import { ClickOutside }from '../../directives/click-outside'
    export default {
        directives: {
            ClickOutside
        },
        props: {
            title: String,
            showBackBtn: Boolean,
            showCloseBtn: Boolean,
            urlNavigatedOnClose: String,
            slide: Boolean,
            modalClass: String,
            headerStyle: String,
            rounded: Boolean,
            closeOnOutsideClick: Boolean
        },
        data: function() {
            return  {
                showModal: false,
                slidingLeft: false,
                slidingRight: false,
                slideInOnMount: this.slide
            }
        },
        methods: {
            show() {
                this.showModal = true;
            },
            close() {
                this.$emit('closed');
                if (this.urlNavigatedOnClose && this.urlNavigatedOnClose !== '') {
                    window.location.replace(this.urlNavigatedOnClose);
                } else {
                    this.showModal = false;
                    document.body.classList.remove('modal-open');
                }
            },
            slideInFromLeft() {
                this.resetSlideCssClass();
                // Give some time to reset the CSS class bound
                setTimeout(() => {
                    this.slidingLeft = true;
                }, 100);

            },
            slideInFromRight() {
                this.resetSlideCssClass();
                // Give some time to reset CSS class bound
                setTimeout(() => {
                    this.slidingRight = true;
                }, 100);
            },
            resetSlideCssClass() {
                this.slideInOnMount= false;
                this.slidingRight = false;
                this.slidingLeft = false;
            },
            onOutsideClicked() {
                this.closeOnOutsideClick && this.close();
            }
        },
        watch: {
            showModal: function() {
                this.$emit('toggled', this.showModal);

                if (this.showModal) {
                    // Prevent scrolling the background
                    document.body.classList.add('modal-open');
                } else {
                    this.$emit('closed');
                    document.body.classList.remove('modal-open');
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../styles/_variables_home.scss";
    @import "../../styles/common_home.scss";

    .modal-mask {
        position: fixed;
        z-index: 9998;
        width: 100%;
        min-height: 100vh;
        background-color: rgba(0, 0, 0, .2);
        transition: opacity .4s ease;
        overflow-y: scroll;
        top: 0; right: 0; bottom: 0; left: 0;
    }

    .modal-wrapper {
        display: flex;
        align-items: center;
        min-height: 100%;
    }

    .modal-container {
        width: 88vw;
        max-width: 400px;
        margin: 0 auto;
        background-color: $white;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
        position: relative;
    }

    .modal-header {
        height: 6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $color-primary;
        color: $white;
    }

    .modal-header h3 {
        margin: 0;
        color: $white;
        text-align: center;
        font-weight: normal;
    }

    .modal-header-btn {
        font-size: 34px;
        cursor: pointer;
        position: relative;
        top: 1px;
        &:hover {
            color: lightgrey;
        }
    }

    .modal-back-btn-wrapper {
        width: 34px;
        text-align: right;
    }

    .modal-close-btn-wrapper {
        width: 34px;
        text-align: left;
        position: relative;
        top: -14px;
        z-index: 1000;
    }

    .modal-body {
        background-color: $white;
    }

    .modal-footer {
        text-align: center;
        background-color: $white;
    }

    .modal-footer p {
        size: 10px;
        color: #eee;
        padding: 10px 20px;
    }

    .modal-enter {
       opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

    $desktop-slide-speed: .8s;
    $mobile-slide-speed: .5s;

    .slide-in {
        animation: slide-right $desktop-slide-speed ease-in-out;
    }

    .slide-left {
        animation: fade-out .75s ease-in-out, slide-left $desktop-slide-speed ease-in-out .75s;
    }

    @keyframes slide-left {
        0% {
            right: 100vw;
        }
        100% {
            right: 0;
        }
    }

    .slide-right {
        animation: fade-out .75s ease-in-out, slide-right $desktop-slide-speed ease-in-out .75s
    }

    @keyframes slide-right {
        0% {
            left: 100vw;
        }
        100% {
            left: 0;
        }
    }

    @keyframes fade-out {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    @media only screen and (max-width: $max-tablet-width) {
        .slide-in {
            animation: slide-right $mobile-slide-speed ease-in-out;
        }
        .slide-left {
            animation: fade-out .75s ease-in-out, slide-left $mobile-slide-speed ease-in-out .75s;
        }
        .slide-right {
            animation: fade-out .75s ease-in-out, slide-right $mobile-slide-speed ease-in-out .75s
        }
        .modal-wrapper {
            display: flex;
            align-items: flex-start;
            min-height: 100%;
            padding: 100px 0;
        }
    }
</style>

<style lang="scss">
    .modal-open {
        height: 100%;
        overflow: hidden;
    }
</style>
